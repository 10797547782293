var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('CCardHeader',{attrs:{"tag":"div"}},[_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" Thêm người dùng ")],1),_c('CCardBody',{staticStyle:{"min-height":"300px"},attrs:{"tag":"div"}},[_c('CForm',[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Họ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseInput',{attrs:{"label":"Họ","horizontal":"","value":_vm.item.firstname,"is-valid":valid,"touched":touched,"invalid-feedback":errors},on:{"update:value":function($event){return _vm.$set(_vm.item, "firstname", $event)}}})]}}],null,true)})],1),_c('CCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Tên","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseInput',{attrs:{"label":"Tên","horizontal":"","value":_vm.item.lastname,"is-valid":valid,"touched":touched,"invalid-feedback":errors},on:{"update:value":function($event){return _vm.$set(_vm.item, "lastname", $event)}}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Tên tài khoản","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseInput',{attrs:{"label":"Tên tài khoản","horizontal":"","value":_vm.item.username,"is-valid":valid,"touched":touched,"invalid-feedback":errors},on:{"update:value":function($event){return _vm.$set(_vm.item, "username", $event)}}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Mật khẩu","vid":"pw_confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseInput',{attrs:{"type":"password","label":"Mật khẩu","horizontal":"","value":_vm.item.password,"is-valid":valid,"touched":touched,"invalid-feedback":errors},on:{"update:value":function($event){return _vm.$set(_vm.item, "password", $event)}}})]}}],null,true)})],1),_c('CCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Xác nhận mật khẩu","rules":"required|confirmed:pw_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseInput',{attrs:{"type":"password","label":"Xác nhận mật khẩu","horizontal":"","value":_vm.pw_confirmation,"is-valid":valid,"touched":touched,"invalid-feedback":errors},on:{"update:value":function($event){_vm.pw_confirmation=$event}}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CInput',{attrs:{"label":"Số điện thoại","horizontal":"","value":_vm.item.phone},on:{"update:value":function($event){return _vm.$set(_vm.item, "phone", $event)}}})],1),_c('CCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseInput',{attrs:{"label":"Email","horizontal":"","value":_vm.item.email,"is-valid":valid,"touched":touched,"invalid-feedback":errors},on:{"update:value":function($event){return _vm.$set(_vm.item, "email", $event)}}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CSelect',{attrs:{"label":"Loại đơn vị","horizontal":"","value":_vm.item.loaiDonVi,"options":_vm.optionsLoaiDonVi},on:{"update:value":function($event){return _vm.$set(_vm.item, "loaiDonVi", $event)}}})],1),_c('CCol',{attrs:{"lg":"6"}},[(_vm.item.loaiDonVi === 2 || _vm.item.loaiDonVi === 3)?[(_vm.item.loaiDonVi === 2)?_c('ValidationProvider',{attrs:{"name":"Tên đơn vị","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseSelect',{attrs:{"label":"Tên đơn vị","horizontal":"","value":_vm.item.donVi_Id,"is-valid":valid,"touched":touched,"invalid-feedback":errors,"options":_vm.optionsDmDonVi},on:{"update:value":function($event){return _vm.$set(_vm.item, "donVi_Id", $event)}}})]}}],null,true)}):_c('ValidationProvider',{attrs:{"name":"Tên đơn vị","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseSelect',{attrs:{"label":"Tên đơn vị","horizontal":"","value":_vm.item.donVi_Id,"is-valid":valid,"touched":touched,"invalid-feedback":errors,"options":_vm.optionsDmTinhThanh},on:{"update:value":function($event){return _vm.$set(_vm.item, "donVi_Id", $event)}}})]}}],null,true)})]:_vm._e()],2)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nhóm quyền","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseSelect',{attrs:{"label":"Nhóm quyền","horizontal":"","value":_vm.item.applicationRoleId,"is-valid":valid,"touched":touched,"invalid-feedback":errors,"options":_vm.optionsApplicationRole},on:{"update:value":function($event){return _vm.$set(_vm.item, "applicationRoleId", $event)}}})]}}],null,true)})],1),_c('CCol',{attrs:{"lg":"6"}})],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('div',{staticClass:"form-group form-row",attrs:{"role":"group"}},[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v("Trạng thái")]),_c('CCol',{staticClass:"form-inline",attrs:{"sm":"9"}},[_c('CInputCheckbox',{attrs:{"label":"Hoạt động","checked":_vm.item.status},on:{"update:checked":function($event){return _vm.$set(_vm.item, "status", $event)}}})],1)],1)])],1)],1),(_vm.isLoading)?_c('CElementCover'):_vm._e()],1),_c('CCardFooter',{staticClass:"text-right",attrs:{"tag":"div"}},[_c('CButton',{staticClass:"mr-2",attrs:{"color":"secondary"},on:{"click":_vm.cancel}},[_vm._v("Hủy bỏ")]),_c('CButton',{attrs:{"color":"primary","disabled":invalid || _vm.isSaving},on:{"click":function($event){return handleSubmit(_vm.saveItem)}}},[_vm._v("Lưu lại")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }