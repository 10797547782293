<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <CCardHeader tag="div">
            <CIcon name="cil-grid"/>
            Thêm người dùng
          </CCardHeader>
          <CCardBody style="min-height: 300px" tag="div">
            <CForm>
              <CRow>
                <CCol lg="6">
                  <ValidationProvider name="Họ" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Họ" horizontal :value.sync="item.firstname" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
                <CCol lg="6">
                  <ValidationProvider name="Tên" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Tên" horizontal :value.sync="item.lastname" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <ValidationProvider name="Tên tài khoản" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Tên tài khoản" horizontal :value.sync="item.username" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <ValidationProvider name="Mật khẩu" vid="pw_confirmation" rules="required"
                                      v-slot="{ errors, valid , touched }">
                    <BaseInput type="password" label="Mật khẩu" horizontal :value.sync="item.password" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
                <CCol lg="6">
                  <ValidationProvider name="Xác nhận mật khẩu" rules="required|confirmed:pw_confirmation"
                                      v-slot="{ errors, valid , touched }">
                    <BaseInput type="password" label="Xác nhận mật khẩu" horizontal :value.sync="pw_confirmation" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CInput label="Số điện thoại" horizontal :value.sync="item.phone"/>
                </CCol>
                <CCol lg="6">
                  <ValidationProvider name="Email" rules="required|email" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Email" horizontal :value.sync="item.email" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CSelect label="Loại đơn vị" horizontal :value.sync="item.loaiDonVi" :options="optionsLoaiDonVi"/>
                </CCol>
                <CCol lg="6">
                  <template v-if="item.loaiDonVi === 2 || item.loaiDonVi === 3">
                    <ValidationProvider name="Tên đơn vị" rules="required" v-slot="{ errors, valid , touched }"
                                        v-if="item.loaiDonVi === 2">
                      <BaseSelect label="Tên đơn vị" horizontal :value.sync="item.donVi_Id" :is-valid="valid"
                                  :touched="touched" :invalid-feedback="errors" :options="optionsDmDonVi"/>
                    </ValidationProvider>

                    <ValidationProvider name="Tên đơn vị" rules="required" v-slot="{ errors, valid , touched }" v-else>
                      <BaseSelect label="Tên đơn vị" horizontal :value.sync="item.donVi_Id" :is-valid="valid"
                                  :touched="touched" :invalid-feedback="errors" :options="optionsDmTinhThanh"/>
                    </ValidationProvider>
                  </template>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <ValidationProvider name="Nhóm quyền" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseSelect label="Nhóm quyền" horizontal :value.sync="item.applicationRoleId" :is-valid="valid"
                                :touched="touched" :invalid-feedback="errors" :options="optionsApplicationRole"/>
                  </ValidationProvider>

                </CCol>
                <CCol lg="6"></CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <div role="group" class="form-group form-row">
                    <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                    <CCol sm="9" class="form-inline">
                      <CInputCheckbox label="Hoạt động" :checked.sync="item.status"/>
                    </CCol>
                  </div>
                </CCol>
              </CRow>
            </CForm>
            <CElementCover v-if="isLoading"/>
          </CCardBody>
          <CCardFooter tag="div" class="text-right">
            <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
<!--            <CButton color="primary" @click="fakeData">fake data</CButton>-->
            <CButton color="primary" @click="handleSubmit(saveItem)" :disabled="invalid || isSaving">Lưu lại</CButton>
          </CCardFooter>
        </ValidationObserver>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CREATE_APPLICATION_USER } from '@/store/modules/application-user/actionTypes'
import { dmDonViService } from '@/services/donvi.service'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { applicationRoleService } from '@/services/application-role.service'
import { enums } from '@/shared/enums'

export default {
  name: 'ApplicationUserCreate',
  data () {
    return {
      item: {
        loaiDonVi: 1
      },
      selectedPermissions: [],
      isLoading: false,
      pw_confirmation: '',
      optionsLoaiDonVi: enums.loaiDonVi,
      optionsDmDonVi: [],
      optionsDmTinhThanh: [],
      optionsApplicationRole: []
    }
  },
  computed: {
    ...mapGetters('applicationUser', {
      isSaving: 'isSaving',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('applicationUser', {
      createItem: CREATE_APPLICATION_USER
    }),
    async saveItem () {
      this.item.applicationRoleId = Number(this.item.applicationRoleId)
      this.item.loaiDonVi = Number(this.item.loaiDonVi)
      this.item.donVi_Id = Number(this.item.donVi_Id)
      await this.createItem(this.item)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/nguoi-dung' })
      }
    },
    cancel () {
      this.$router.push({ path: '/nguoi-dung' })
    },
    fakeData () {
      this.item.applicationRoleId = 4
      this.item.donVi_Id = 1
      this.item.email = 'hieu@gmail.com'
      this.item.firstname = 'UBND TP'
      this.item.lastname = 'Hà Nội'
      this.item.loaiDonVi = 3
      this.item.password = 'hieu@123'
      this.item.phone = '324232232'
      this.item.status = true
      this.item.username = 'ubndtphn'
      this.pw_confirmation = this.item.password
    }
  },
  watch: {
    'item.loaiDonVi': function (newVal, oldVal) {
      this.item.donVi_Id = null
      applicationRoleService.getByLoaiDonVi(newVal).then(data => {
        this.optionsApplicationRole = data
      })
    }
  },
  async mounted () {
    this.isLoading = true
    this.optionsDmDonVi = await dmDonViService.getAll()
    this.optionsDmTinhThanh = await tinhThanhService.getAll()
    this.optionsApplicationRole = await applicationRoleService.getByLoaiDonVi(this.item.loaiDonVi)
    this.isLoading = false
  }
}
</script>
